const version = ENV.version
const baseUrl = ENV.assetsBaseUrl
const queryVersion = '?v=' + version
const minify = ENV.minify

const srcScripts = [baseUrl + 'js/publicInfo' + (version ? '.' + version + ENV.minify : '') + '.js' + queryVersion]

const srcCss = [baseUrl + 'css/tailwind.css' + queryVersion]

const refScript = document.getElementsByTagName('script')[0]
const refCss = document.getElementsByTagName('title')[0]

srcCss.forEach(href => {
  const newCss = document.createElement('link')
  newCss.rel = 'stylesheet'
  newCss.href = href
  refCss.parentNode.append(newCss, refCss)
})

srcScripts.forEach(src => {
  const newScript = document.createElement('script')
  newScript.src = src
  newScript.crossOrigin = 'anonymous'
  refScript.parentNode.append(newScript, refScript)
})
